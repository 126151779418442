import { useRef, useState, useEffect, memo } from "@messenger/lib/teact/teact";
import { MTProtoStore, useMTProtoStore } from "@messenger/global/mtproto";
import { Api } from "@messenger/lib/gramjs";
import { callApi } from "@@/src/app/api/gramjs";

const enum Messages {
  VERIFICATION_FINISHED = "VERIFICATION_FINISHED",
}

class Captcha {
  private iframeRef: HTMLIFrameElement | null = null;
  private container: HTMLDivElement | null = null;
  private url: string | null = null;
  private isVisible: boolean = false;

  constructor() {
    this.init();
  }

  // Initialize Captcha Modal
  init() {
    if (!document.getElementById("captcha")) {
      this.createCaptchaContainer();
      const unsub = MTProtoStore.subscribe(async (state, prevState) => {
        this.checkNeedCaptcha(state.needCaptcha);
      });

      window.addEventListener("message", this.handleMessage.bind(this));
    }
  }

  private createCaptchaContainer() {
    this.container = document.createElement("div");
    this.container.id = "captcha";
    this.container.className =
      "fixed z-50 left-0 top-0 w-full h-full bg-black/30 flex justify-center items-center transition-opacity duration-300 opacity-0";
    this.container.style.display = "none";

    this.iframeRef = document.createElement("iframe");
    this.iframeRef.frameBorder = "0";
    this.iframeRef.setAttribute("sandbox", "allow-scripts");
    this.iframeRef.setAttribute("frameborder", "0");
    this.iframeRef.setAttribute("allowtransparency", "true");
    this.iframeRef.className =
      "min-h-10 h-[inherit] min-w-10 w-auto bg-transparent rounded-xl";

    this.container.appendChild(this.iframeRef);
    document.body.appendChild(this.container);
  }

  private async checkNeedCaptcha(needCaptcha: boolean) {
    if (needCaptcha) {
      this.show();
      try {
        const resp = (await callApi(
          "requestVerification",
        )) as Api.auth.Verification;
        if (resp && resp.url) {
          this.setUrl(resp.url);
        } else {
          this.hide();
          setTimeout(() => {
            MTProtoStore.setState({ needCaptcha: false });
          }, 300);
          const req = MTProtoStore.getState().request;
          callApi("retry", req ? req : new Api.auth.RequestVerification());
        }
      } catch (e) {
        console.error("Captcha Error:", e);
      }
    }
  }

  private handleMessage(event: MessageEvent) {
    if (event.data?.event === Messages.VERIFICATION_FINISHED) {
      this.hide();
      MTProtoStore.setState({ needCaptcha: false });
      const req = MTProtoStore.getState().request;
      callApi("retry", req ? req : new Api.auth.RequestVerification());
    }
  }

  private setUrl(url: string) {
    this.url = url;
    if (this.iframeRef) this.iframeRef.src = url;
  }

  private show() {
    this.isVisible = true;
    if (this.container) {
      this.container.style.display = "flex";
      this.container.style.opacity = "1";
    }
  }

  private hide() {
    this.isVisible = false;
    if (this.container) {
      this.container.style.opacity = "0";
      this.container!.style.display = "none";
    }
  }
}

export const createCaptcha = () => {
  const captcha = new Captcha();
  captcha.init();
};
